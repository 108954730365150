/* Main */
#root {
    height: 100vh;
    /* Set the height of the #root element to 100vh */
    align-items: center;
    /* Center vertically */
}

body {
    background-color: #141414;
    color: #ffffff;
}

/* Sign in */
.form-signin {
    width: 100%;
    padding: 15px;
    margin: auto;
    background-color: #1f1f1f;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-signin-width {
    max-width: 330px;
}

.form-signin-min-width {
    min-width: 330px;
}

.form-signin .form-floating {
    margin-bottom: 15px;
}

.form-signin .form-floating label {
    color: #ffffff;
}

.form-signin .form-floating input,
.form-signin .form-floating textarea {
    background-color: #333333;
    color: #ffffff;
    border: none;
}

.form-signin .form-floating input:focus,
.form-signin .form-floating textarea:focus {
    background-color: #444444;
    color: #ffffff;
}

.form-signin .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.form-signin .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.alert-danger {
    background-color: #721c24;
    color: #ffffff;
    border-color: #721c24;
}

/* Additional styles */
.form-signin .mb-3 a {
    color: #007bff;
    text-decoration: none;
}

.form-signin .mb-3 a:hover {
    color: #0056b3;
}

/* Adjust margin for the forgot password link */
.form-signin .mb-3 {
    margin-top: 15px;
}

/* Add space between error message and sign in button */
.form-signin .alert-danger.mb-3 {
    margin-top: 15px;
}

.form-signin-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* Align content to the top */
    height: 80vh;
    /* Adjust the height as needed */
}

.form-register-content {
    margin-top: 20px;
    margin-bottom: 50px;
    /* This is a specific height to support registration form */
    padding-top: 42px;
}

.form-register-content-image {
    height: 55px;
    /* This is a specific height to support registration form */
}

.account-change-picture {
    height: 244px;
    /* This is a specific height to support registration form */
}

.account-change-venmo {
    height: 320px;
    /* This is a specific height to support registration form */
}

.account-image {
    height: 55px;
    /* This is a specific height to support registration form */
}

.update-ladder-delete {
    height: 250px;
}

.update-user-delete {
    height: 244px;
}

.form-register-ladder-content {
    height: 1300px;
    /* This is a specific height to support registration form */
}

.form-register-event-content {
    height: 800px;
    /* This is a specific height to support registration form */
}

.form-update-event-content {
    height: 600px;
    /* This is a specific height to support registration form */
}

/* Add this CSS code to style the form select */
.form-signin .form-floating select {
    background-color: #333333;
    color: #ffffff;
    border: none;
    padding: 0.375rem 1rem;
    /* Adjust padding as needed */
    appearance: none;
    /* Remove default arrow */
    -webkit-appearance: none;
    /* Remove default arrow for Safari */
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path d='M2 2l2 2 2-2H2z'/></svg>");
    background-size: 20px;
    /* Adjust the size as needed */
    background-repeat: no-repeat;
    background-position: right center;
    border-radius: 0.25rem;
    /* Add rounded corners */
    transition: background-color 0.2s, border-color 0.2s, color 0.2s;
    /* Add smooth transition */
}

.form-signin .form-floating select:focus {
    background-color: #444444;
    color: #ffffff;
}

/* Style the select options */
.form-signin .form-floating select option {
    background-color: #333333;
    color: #ffffff;
}

/* Style the select options on hover */
.form-signin .form-floating select option:hover {
    background-color: #444444;
    color: #ffffff;
}

/* Center "Select an Activity" vertically */
.form-signin .form-floating select option[value=""] {
    display: none;
    /* Hide the placeholder option */
}

/* Style the select when it's open */
.form-signin .form-floating select:active,
.form-signin .form-floating select:focus,
.form-signin .form-floating select:valid {
    border-color: #007bff;
}

/* Prevent autofill from changing the background color */
.form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #333333 inset;
    /* Change the background color to your desired color */
    -webkit-text-fill-color: #fff !important;
    /* Change the text color to white */
}

.active {
    color: #0098b9 !important;
    /* You can add more styles as needed */
}

.form-control-image {
    background-color: #444444;
    color: white;
}

/* Add this CSS to create the divider */
.divider {
    border-top: 1px solid #ccc;
    /* Define the divider style */
    margin: 20px 0;
    /* Adjust the margin as needed */
}

/* Add this CSS to create the divider */
.nav-divider {
    border-top: 1px solid #ccc;
    margin: 10px 0;
    /* Adjust the margin as needed */
}

/* styles/LandingPage.css */
.landing-page {
    font-family: Arial, sans-serif;
}

.landing-page-header {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center; /* Center the containers horizontally */
    height: 600px;
}

.landing-page-left-container, .landing-page-right-container {
    flex: 1; /* Each container takes equal space */
    display: flex;
    align-items: center;
    height: 100%;
}

.landing-page-left-container {
    justify-content: flex-end; /* Align items to the right within the container */
    padding-right: 25px;
}

.landing-page-right-container {
    justify-content: flex-start; /* Align items to the left within the container */
    padding-left: 25px;
}

.landing-page-image {
    background-image: url('images/ball.png');
    background-size: contain;  /* Ensure the image fits without stretching */
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    position: relative;
    width: 500px; /* Ensures the image container uses all available space */
    height: 500px;
}

.landing-page-content {
    width: 100%; /* Ensures the content container uses all available space */
}

.landing-page-title {
    font-size: 2.5rem;
}

.landing-page-description {
    font-size: 1.5rem;
}

.cta-button {
    display: inline-block;
    background-color: #ff7f50;
    color: white;
    padding: 12px 30px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.2rem;
    transition: background-color 0.3s;
}

.ladder-page-update-ladder {
    display: inline-block;
    background-color: #ff7f50;
    color: white;
    text-decoration: none;
    transition: background-color 0.3s;
    padding: 10px 23px 10px 23px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 0.9rem;
    border: none;
    width: 160px;
    text-align: center;
}

.ladder-page-update-ladder:hover {
    transform: scale(1.05);
    /* Slightly enlarge the button */
    color: white;
}

.ladder-page-host-options {
    margin-top: 10px;
}

.ladder-page-go-to-chat {
    margin-top: 40px;
    margin-bottom: 24px;
    background-color: teal;
}

.ladder-page-go-to-chat-host {
    margin-top: 15px;
    margin-bottom: 0;
}

.profile-page-go-to-chat {
    margin-left: 20px;
    background-color: teal;
}

.cta-button:hover {
    background-color: #ff3d3d;
    color: white;
}

.logo-container {
    width: 200px;
    height: 50px;  /* Define a height for the container */
    margin-right: 5rem;
    margin-left: 8px;
    display: flex;  /* Utilizes Flexbox for centering */
    align-items: center;  /* Vertically center the content of the container */
    justify-content: center;  /* Horizontally center the content of the container */
}

.logo {
    width: 100%;
    height: 100%;
    background-image: url('images/word_logo.png');  /* Set the background image */
    background-size: contain;  /* Ensure the image fits within the container without stretching */
    background-position: center;  /* Center the background image */
    background-repeat: no-repeat;  /* Do not repeat the background image */
    display: flex;  /* Use flex to center the link content */
    align-items: center;  /* Center content vertically */
    justify-content: center;  /* Center content horizontally */
    text-decoration: none;  /* Remove underline from the link */
    color: #00c3e4;  /* Text color */
    font-size: 24px;  /* Text size */
    font-weight: bold;  /* Text weight */
    font-family: 'Arial', sans-serif;  /* Font family */
}

.logo:hover {
    color: #0098b9;
    /* A slightly darker shade on hover */
    transform: scale(1.05);
    /* Slight scale increase on hover for an edgy effect */
}

.nav-items {
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    /* You can choose a different font */
    color: white;
    text-decoration: none;
    /* Remove underline from link */
    transition: color 0.3s;
    /* Smooth color transition on hover */
    margin-right: 5rem;
}

.nav-items:hover {
    color: white;
    transform: scale(1.05);
    /* Slight scale increase on hover for an edgy effect */
}

.nav-content {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-content {
    margin-top: 40px;
}

.active {
    color: #0098b9 !important;
    /* You can add more styles as needed */
}

/* App.css */

/* Slider Container */
.slider-container {
    width: 100%;
    margin-bottom: 30px;
}

/* Slide */
.slide {
    flex: 0 0 auto;
    width: 100%; /* Fixed width of the slide */
    scroll-snap-align: start;
    padding-left: 14px;
}

/* Last Slide Margin Fix */
/* Add a pseudo-element after the last slide to ensure the right margin is respected */
.slider::after {
    content: '';
    flex: 0 0 20px; /* Match your slide margin */
}

/* Card Styling */
.card {
    width: 100%;
    height: auto; /* Keep the height auto to maintain aspect ratio */
}

/* Image Styling */
.card img {
    width: 100%; /* Fixed width of the image */
    height: 225px; /* Fixed height of the image */
    object-fit: cover; /* Cover to ensure the image covers the area */
}

/* Custom Slick slider overrides to left-align the slides */
.slick-slider .slick-track {
    margin-left: 0;
}

.slick-slider .slick-list {
    text-align: initial; /* Override the center text-align */
}

/* Increase specificity by repeating the class */
.slick-slider .slick-dots li button:before,
.slick-slider .slick-dots li button:before {
    color: white; /* Override any other styles with !important */
}

.slick-slider .slick-dots li.slick-active button:before,
.slick-slider .slick-dots li.slick-active button:before {
    color: white; /* Keep active dot white */
}

.card:hover {
    border-color: #007d94;
    /* Change border color on hover */
}

/* Ladder Info Styling */
.ladder-info {
    padding: 15px;
    /* Adjust padding as needed */
}

.ladders-heading {
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
    margin-bottom: 1rem;
    /* Add some spacing below the heading and button */
    margin-left: 1rem;
}

.ladder-info {
    padding: 0.375rem 1rem;
    /* Consistent padding */
    background-color: #333333;
    /* Dark background to match */
    color: #ffffff;
    /* White text */
    border: none;
    border-top: 1px solid #444444;
    /* Slight separator to match focus background color */
    transition: background-color 0.2s, color 0.2s;
    /* Smooth transitions */
}

.ladder-info:hover {
    background-color: #444444;
    /* Darken on hover */
}

.ladder-info h4 {
    margin: 0;
    font-size: 1.2em;
    transition: color 0.2s;
    /* Add smooth transition for hover */
}

.ladder-info p {
    margin: 0.5em 0;
    transition: color 0.2s;
    /* Add smooth transition for hover */
}

.matchhistory-custom-link {
    text-decoration: none;
    /* Remove underline */
    color: inherit;
    /* Use the inherited color, or you can set it to a specific color */
}

.matchhistory-custom-link:hover,
.matchhistory-custom-link:focus {
    text-decoration: none;
    /* Ensure that underline doesn't appear on hover or focus */
}

.container-signin-discover {
    padding-bottom: 0;
    margin: 0 0 10px 0;
    width: 100%;
}

.form-signin-discover {
    display: flex;
    /* This will make its children sit side-by-side */
    gap: 1rem;
    /* This will provide space between dropdowns */
    flex-wrap: wrap;
}

.dropdown-discover {
    flex: 1;
    /* This will make each dropdown take up an equal amount of space */
    width: 300px;
    max-width: 300px;
    min-width: 300px;
}

.landing-page-search-button {
    flex: 1;
    /* This will make each dropdown take up an equal amount of space */
    width: 100px;
    border-radius: 40px;
    max-width: 140px;
    min-width: 100px;
    height: 100%;
}

.discover-search-button {
    height: 58px;
    margin-bottom: 20px;
}

.discover-header {
    margin-left: 30px;
    margin-top: 20px;
}
.discover-left-square {
    max-width: 1270px;
    min-width: 1270px;
    padding: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border: none;
}

.discover-right-square {
    width: 400px;
    height: 600px;
    border-radius: 20px;
    border: 1px solid #ccc;
    /* Optional: Adds a thin border */
    overflow: hidden;
    margin: 10px;
    min-width: 400px;
}

.discover-right-square-top {
    width: 400px;
    height: 325px;
    border-radius: 20px;
    border: 1px solid #ccc;
    /* Optional: Adds a thin border */
    overflow: hidden;
    min-width: 400px;
    margin: 5px 5px 5px;
}

.discover-events-reset-calendar {
    margin-bottom: 40px;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
}

.ladder-page-container {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    /* Ensuring the container takes full width */
    padding: 20px;
}

.ladder-page-container2 {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    /* Ensuring the container takes full width */
    padding: 20px;
}

.ladder-page-container-host {
    display: flex;
    align-items: flex-start;
    width: 100%;
    /* Ensuring the container takes full width */
    padding: 10px;
}

.ladder-page-container2-no-top-padding {
    padding-top: 0;
}

.ladder-page-image {
    width: 40vw;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
    margin: 20px;
}

.ladder-page-info {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* This will stack child elements vertically */
    justify-content: center;
    /* This will center child elements vertically */
    background: transparent;
}

.ladder-page-description {
    width: 40vw;
    margin: 20px;
    white-space: pre-line;
    /* Allows text to wrap to next line */
    word-wrap: break-word;
    /* Breaks long words if necessary to prevent overflow */
}

.ladder-page-description2 {
    width: 50vw;
    margin: 20px;
    white-space: pre-line;
    /* Allows text to wrap to next line */
    word-wrap: break-word;
    /* Breaks long words if necessary to prevent overflow */
}

.info-row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    /* Adjust as needed */
}

.ladder-page-para {
    margin: 5px;
}

.join-button,
.joined-button {
    padding: 10px 50px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 0.9rem;
    border: none;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
    /* Added transform for a slight scale */
    outline: none;
    width: 160px;
    text-align: center;
}

.join-button {
    max-width: 160px;
    background-color: teal;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 24px;
}

.join-button:hover {
    background-color: #f1f1f1;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.joined-button {
    max-width: 160px;
    background-color: #757575;
    /* Darker gray for a subdued appearance */
    color: white;
    cursor: not-allowed;
    /* Indicate that it's not clickable */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
    margin-bottom: 24px;
}

.join-button.joined:hover {
    transform: scale(1.05);
    /* Slightly enlarge the button */
    background-color: darkgray;
}

.join-button:not(.joined):hover {
    background-color: teal;
    color: white;
    transform: scale(1.05);
    /* Slightly enlarge the button */
}

.joined-button.joined:hover {
    transform: scale(1.05);
    /* Slightly enlarge the button */
    background-color: darkgray;
}

.joined-button:not(.joined):hover {
    color: white;
    transform: scale(1.05);
    /* Slightly enlarge the button */
}

.score-submit-button {
    margin-top: 5px;
    text-align: center;
}

#floatingDescription {
    min-height: 250px;
    max-height: 250px;
    width: 100%;
    resize: vertical;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: pre-wrap;
    text-align: left;
    word-break: break-word;

    /* Add these lines to match the styling of other input fields */
    background-color: #333333;
    color: #ffffff;
    border: none;
    /* It will remove the border, but you can adjust if needed */
}

.snackbar {
    visibility: visible;
    min-width: 250px;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 20px;
    /* This rounds the box */
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    /* Adding a subtle shadow */
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.profile-image {
    width: 40px;
    /* Set the width as desired */
    height: 40px;
    /* Set the height as desired */
    border-radius: 50%;
    /* Makes the image circular */
    object-fit: cover;
    /* Ensures the image covers the entire container without distortion */
    transition: transform 0.3s ease;
    border: 1px solid white;
}

.profile-link:hover .profile-image {
    transform: scale(1.2);
    /* Enlarges the image by 20% when hovered */
}

/* Profile Page */
.profile-banner-container {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr;
    gap: 20px;
    border-bottom: 2px solid #a0a096;
    border-radius: 5%;
    /* Adjust the gap as needed */

}

.profile-banner-column {
    /* Optional: Add borders for clarity */
    padding: 10px;
    border-radius: 8px;

}

.profile-match-history-container {
    width: 80%;
    /* Set width to half of the viewport width */
    height: 50vh;
    /* Set height to half of the viewport height */
    max-height: 50vh;
    /* Ensure maximum height does not exceed half of the viewport height */
    overflow: auto;
    /* Enable scrolling if the content exceeds the container size */
    position: relative;
    /* Optional: May be required to position the container */
    top: 50%;
    /* Optional: Adjust to align as needed, centers the box vertically */
    left: 20%;
    /* Optional: Adjust to align as needed, centers the box horizontally */
    scrollbar-width: none;
    /* Optional: Hides the scrollbar in Firefox */
}

/* .profile-calendar-container {
    display: flex;
    align-items: center;
    justify-content: center;
} */

.calendar-day-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* Align items to the start of the container */
}

.calendar-calendar-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* if you want to align items to the start of the cross axis */
    justify-content: flex-start;
    /* this ensures content starts from the top */
}

.react-calendar {
    max-width: 100%;
    background: none;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border-radius: 8px;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    background-color: #0098b9;
    height: 44px;
    border-radius: 8px 8px 0 0;
}

.react-calendar__navigation button {
    min-width: 50px;
    background-color: #0098b9;
    border-radius: 8px;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: black;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    margin: 1px;
    background: rgba(0, 152, 185, 0.25);
    text-align: center;
    color: white;
    line-height: 32px;
    font-family: 'Arial', sans-serif;
    font-size: 1em;
    height: 50px;
}

.react-calendar__tile:disabled {
    background-color: rgba(169, 169, 169, .1);
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: lightblue;
    transform: scale(1.05);
}

.react-calendar__tile--now {
    background: rgba(0, 195, 228, 0.25);
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #00C3E4;
}

.react-calendar__tile--hasActive {
    background: rgba(118, 186, 255, .25);
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
    background: rgba(0, 110, 220, 0.25);
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.Calendar-event-dot {
    height: 5px;
    width: 5px;
    background-color: #ec5915;
    border-radius: 50%;
    display: inline-block;
}

.calendar-event-dot-blank {
    height: 5px;
    width: 5px;
    background-color: inherit;
    border-radius: 50%;
    display: inline-block;
}

.ladderranks-ladder-list-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding: 5px 10px;
    /* Add padding for better spacing */
}

.ladderranks-ladder-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1px 5px;
    /* Add padding for better spacing */
    border: 1px solid #ccc;
    /* Adds a light grey border */
    margin-bottom: 5px;
    /* Adds a little space between items */
    border-radius: 5px;
    /* Adds rounded corners */
}

.ladderranks-rank,
.ladderranks-name {
    flex: 1;
    margin: 1px 5px;
    flex-basis: 50%;
    /* Equal width for both elements */
}

.ladderranks-name {
    font-weight: bold;
    text-align: left;
    /* Now aligns the name to the left */
}

.ladderranks-rank {
    font-size: 1em;
    font-weight: bold;
    color: #ec5915;
    /* Or any color that stands out */
    background-color: #f0f0f000;
    /* Light background for contrast */
    text-align: right;
    /* Now aligns the rank to the right */
}

.account-container {
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    align-items: center;
    /* Center children horizontally */
    text-align: left;
    /* Center text for when it's under the image */
}

.account-image-container {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    width: 100%;
    /* You can set a specific size if you prefer */
    height: auto;
    /* Or set a specific height */
    max-width: 300px;
}

.account-img {
    max-width: 100%;
    /* Ensure the image is responsive and fits the container */
    height: auto;
    /* Maintain aspect ratio */
    border: 1px solid white;
    /* Add a white border */
    border-radius: 50%;
    /* Make it round */
}

.logout-div {
    margin-top: 10px;
}

.account-info {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* This will stack child elements vertically */
    justify-content: center;
    /* This will center child elements vertically */
    background: transparent;
    margin-left: 25vw;
    margin-top: 20px;
    max-width: 400px;
}

.form-account-content {
    height: 800px;
    /* This is a specific height to support registration form */
    padding-top: 42px;
}

.event-page-waitlist-header {
    margin-top: 30px;
}

.ladder_members_user-pill {
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: 20px;
    padding: 10px;
    list-style-type: none;
    border: 1px solid white;
    margin-bottom: 5px;
    margin-right: 10px;
    flex: 1; /* Takes up only necessary space */
    max-width: 300px;
    width: 300px;
}

.ladder_members_host-pill {
    align-items: center;
    background-color: transparent;
    border-radius: 20px;
    padding: 10px;
    list-style-type: none;
    border: 1px solid white;
    margin-bottom: 5px;
    margin-right: 10px;
    flex: 1; /* Takes up only necessary space */
    display: block;
    max-width: 330px;
}

.ladder_members_li {
    display: flex;
}

.ladder-members-scores {
    display: flex;
    align-items: center;
}

.ladder_members_user-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.ladder_members_rank-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* Aligns content to the right */
    flex-grow: 1;
    /* Allows the container to take up the remaining space */
    padding-right: 5px;
}

.ladder_members_ul {
    padding-left: 0;
    padding-right: 10px;
    /*height: 1000px;*/
    /* Adjust the height as needed */
    overflow-y: auto;
    width: 350px;
}

.ladder_members_ul_height {
    max-height: 605px;
    height: 100%;
    width: 330px;
}

.ladder_members_ul_player_matchup {
    padding-left: 0;
    padding-right: 10px;
    margin: 0;
    overflow-y: auto;
    /* Enable scrolling */
}

.event_page-note-to-players {
    font-style: italic;
    /* Italic font for note-like appearance */
    color: yellow;
    /* Dark grey font color */
    font-size: 0.9em;
    /* Slightly smaller font size */
}

.event_page-note-to-players2 {
    font-style: italic;
    /* Italic font for note-like appearance */
    color: yellow;
    /* Dark grey font color */
    font-size: 0.9em;
    /* Slightly smaller font size */
    margin-top: 20px;
}

.tennis-scoreboard-table {
    border-collapse: separate;
    /* Updated to separate for rounded corners */
    border-spacing: 0;
    /* Removes default spacing between cells */
    width: 100%;
    color: white;
    margin-top: 50px;
    border-radius: 10px;
    /* Adds a rounded border to the table */
    overflow: hidden;
    /* Ensures the content doesn't overflow the rounded corners */
}

/* First row */
.tennis-scoreboard-table tr:first-child th:first-child {
    border-top-left-radius: 10px;
    /* Rounds the top-left corner of the first header */
}

.tennis-scoreboard-table tr:first-child th:last-child {
    border-top-right-radius: 10px;
    /* Rounds the top-right corner of the last header */
}

/* Last row */
.tennis-scoreboard-table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
    /* Rounds the bottom-left corner of the first cell */
}

.tennis-scoreboard-table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
    /* Rounds the bottom-right corner of the last cell */
}

.tennis-scoreboard-table th,
.tennis-scoreboard-table td {
    border: 1px solid white;
    text-align: center;
    padding: 8px;
}

.tennis-scoreboard-table th.player-name,
.tennis-scoreboard-table td.player-name {
    width: 40%;
}

.tennis-scoreboard-table th.set-score,
.tennis-scoreboard-table td.set-score {
    width: 15%;
}

.tennis-scoreboard-table input[type='number'] {
    background-color: transparent;
    border: none;
    color: white;
    width: 50px;
    text-align: center;
    -webkit-appearance: none;
    /* For WebKit browsers */
    -moz-appearance: textfield;
    /* For Mozilla Firefox */
}

/* For WebKit browsers */
.tennis-scoreboard-table input[type='number']::-webkit-inner-spin-button,
.tennis-scoreboard-table input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.tennis-scoreboard-table input[type='number']:focus {
    outline: none;
}

.matchup_card {
    margin-bottom: 50px;
}

/* Styles for the scrollbar track (the background of the scrollbar) */
.ladder_members_ul::-webkit-scrollbar-track {
    background-color: #2e2e2e;
    /* Dark grey track */
    border-radius: 10px;
    /* Rounded corners */
}

/* Styles for the scrollbar thumb (the moving part of the scrollbar) */
.ladder_members_ul::-webkit-scrollbar-thumb {
    background-color: #555;
    /* Medium grey thumb */
    border-radius: 10px;
    /* Rounded corners */
    border: 3px solid #2e2e2e;
    /* Creates a margin between the thumb and track */
}

/* Changes the thumb color on hover or when being clicked */
.ladder_members_ul::-webkit-scrollbar-thumb:hover {
    background-color: #787878;
    /* Lighter grey for hover effect */
}

/* Styles for the scrollbar itself (width and height) */
.ladder_members_ul::-webkit-scrollbar {
    width: 8px;
    /* Width of the vertical scrollbar */
    height: 8px;
    /* Height of the horizontal scrollbar */
}

.ladder_members_rank-arrow {
    color: green;
    margin-right: 8px;
    /* Space between the arrow and the rank */
    font-size: 1em;
    /* Slightly larger icon */
    transition: transform 0.2s ease;
    /* Smooth transition for hover effect */
}

.ladder_members_rank-arrow:hover {
    transform: translateY(-2px);
    /* Slight upward movement on hover */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    /* Subtle shadow effect */
}

.update-ladder-container1 {
    margin-top: 40px;
}

.update-ladder-container2 {
    margin-top: 180px;
}

.update-ladder-container3 {
    margin-top: 40px;
}

.update-event-container {
    margin-top: 20px;
}

.update-ladder-container4 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.back-to-ladder-button {
    background: none;
    color: white;
    /* Primary color for text */
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    transition: color 0.3s ease;
}

.back-to-ladder-button span {
    margin-left: 10px;
}

.back-to-ladder-button:hover {
    transform: scale(1.05);
    /* Slightly increase size on hover */
}

.back-button-absolute {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

.dropdown-menu {
    position: absolute;
    right: 0;
    transform: translateY(16px);
    background-color: #343a40;
    /* Dark gray background for the dropdown */
    border-radius: 10px;
}

.nav-dropdown-item {
    color: white;
}

.dropdown-item:active {
    background-color: #0098b9;
    /* Keeps the background color the same on click */
    /*color: inherit; !* Ensures text color does not change on click *!*/
}

.nav-no-border-on-active:focus,
.nav-no-border-on-active:active {
    outline: none;
    /* Removes the outline on focus and active states */
    border: none;
    /* Removes borders on focus and active states */
    box-shadow: none;
    /* Removes any box shadow that might be applied on focus or active */
}

.nav-login-register {
    width: 80px;
    margin-top: 4px;
}

.create-ladder-button {
    margin-bottom: 0;
    margin-top: 10px;
    margin-left: 20px;
}

.discover-event-header {
    margin-top: 20px;
}

.discover-checkbox-container {
    display: flex;
    flex-direction: column;
}

.discover-header-container {
    display: flex;
    align-items: center; /* Adjust vertical alignment as needed */
    justify-content: center; /* Adjust spacing or alignment as needed */
    max-width: 1455px;
}

.discover-header-container-landing {
    width: fit-content;
}

.discover-ladder-margin {
    margin-left: 25px;
}

.matchhistory-custom-table-container {
    max-height: 80vh;
    overflow-y: auto; /* Makes content scrollable vertically if it exceeds the container's height */
    max-width: 650px;
}

/* Style the scrollbar itself (width) */
.matchhistory-custom-table-container::-webkit-scrollbar {
    width: 8px; /* Adjust the width of the scrollbar */
}

/* Style the scrollbar track */
.matchhistory-custom-table-container::-webkit-scrollbar-track {
    background: #2e2e36; /* Lighter shade for the track */
    border-radius: 10px;
}

/* Style the scrollbar thumb */
.matchhistory-custom-table-container::-webkit-scrollbar-thumb {
    background-color: #555; /* Color of the scrollbar thumb */
    border-radius: 10px;
    border: 2px solid #2e2e36; /* Optional: Adds border matching the track color */
}

/* Optional: Style the scrollbar thumb on hover */
.matchhistory-custom-table-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Slightly darker shade for hover effect */
}

.matchhistory-custom-table {
    min-width: 640px;
    width: 100%;
}

.matchhistory-custom-table thead tr,
.matchhistory-custom-table thead th {
    border-top: none;
    border-bottom: none !important;
    color: #fff;
    font-size: large;
}

.matchhistory-date-padding {
    padding-left: 20px; /* Adjusts left padding for the date cells */
    width: 150px;
}

.matchhistory-set-padding {
    padding-right: 20px;
}

.matchhistory-custom-table tbody th,
.matchhistory-custom-table tbody td {
    color: #777;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: 300;
}

.matchhistory-custom-table tbody th small,
.matchhistory-custom-table tbody td small {
    color: #b3b3b3;
    font-weight: 300;
}

.matchhistory-custom-table tbody tr:not(.spacer) {
    border-radius: 7px;
    overflow: hidden;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.matchhistory-custom-table tbody tr:not(.spacer):hover {
    -webkit-box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
}

.matchhistory-custom-table tbody tr th,
.matchhistory-custom-table tbody tr td {
    background: #25252b;
    border: none;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.matchhistory-custom-table tbody tr th a,
.matchhistory-custom-table tbody tr td a {
    color: #b3b3b3;
}

.matchhistory-custom-table tbody tr th:first-child,
.matchhistory-custom-table tbody tr td:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.matchhistory-custom-table tbody tr th:last-child,
.matchhistory-custom-table tbody tr td:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.matchhistory-custom-table tbody tr.spacer td {
    padding: 0 !important;
    height: 3px;
    border-radius: 0 !important;
    background: transparent !important;
}

.matchhistory-custom-table-row tbody div.active th,
.matchhistory-custom-table-row tbody div.active td,
.matchhistory-custom-table-row tbody div:hover th,
.matchhistory-custom-table-row tbody div:hover td {
    color: #fff;
    background: #2e2e36;
}

.matchhistory-custom-table-row tbody div.active th a,
.matchhistory-custom-table-row tbody div.active td a,
.matchhistory-custom-table-row tbody div:hover th a,
.matchhistory-custom-table-row tbody div:hover td a {
    color: #fff;
}

.custom-link,
.custom-link p {
    text-decoration: none;
    /* Removes underline */
}

.profile-custom-link {
    display: flex; /* This keeps the flex layout inside the link */
    align-items: center; /* Centers the items vertically */
    text-decoration: none; /* Removes the underline from the link */
    color: inherit;
}

.profile-custom-link:hover, .profile-custom-link:active {
    color: #0098b9; /* Sets the hover and active color to #0098b9 */
}

/* App.css */
.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-main-content {
    flex: 1;
}

.profile-pill-container {
    border-radius: 10px; /* More square-like corners */
    padding: 20px; /* Ample padding for content */
    display: flex;
    flex-direction: column;
    background-color: transparent; /* Replace with your actual background color */
}

.profile-about-me-header {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px; /* Adjust as needed for spacing between header/edit button and content */
}

.profile-edit-btn {
    background-color: transparent; /* Transparent background */
    border: none;
    cursor: pointer;
    color: white; /* Choose a modern color */
    transition: color 0.3s ease;
    padding-bottom: 10px;
}

.profile-form-control, .profile-about-me-text {
    width: 100%; /* Ensure textarea and text fit container width */
    margin-top: 10px; /* Space from the header or edit button */
}

.profile-user-info {
    display: flex;
    align-items: center; /* Aligns the image and the name vertically */
    margin-bottom: 20px; /* Adjust as needed for spacing below the user info */
}

.profile-img {
    border-radius: 5%; /* Circular image, adjust as needed */
    width: 300px; /* Adjust size as needed */
    height: 100%; /* Adjust size as needed */
    margin-right: 20px; /* Space between image and name */
}

.profile-submit-btn {
    background-color: #0098b9; /* A modern color for your button */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px; /* Slightly rounded corners for a modern look */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

.profile-user-name {
    font-size: 20px; /* Adjust size as needed */
    font-weight: bold; /* Optional: for emphasizing the name */
    /* Additional styling as needed */
}

.profile-ladders-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the space between the pills as needed */
    justify-content: flex-start;
    margin-top: 20px; /* Space from the above section */
}

.profile-ladder-page-description {
    margin-top: 20px;
}

.profile-ladder-pill {
    display: flex;
    align-items: stretch;
    border: 1px solid #ccc; /* Adjust border color as needed */
    border-radius: 10px; /* More square-like borders */
    overflow: hidden; /* Keeps the child image within the border-radius */
    margin-bottom: 10px;
    width: calc(50% - 5px); /* Adjust the width for two pills per row, considering the gap */
    max-width: 250px; /* Adjust maximum width as needed */
}

.profile-ladder-image {
    width: 60px; /* Adjust width as needed */
    height: 100%; /* Adjust height as needed */
    object-fit: cover; /* Ensures the image covers the area without stretching */
}

.profile-ladder-details {
    padding: 10px;
    flex-grow: 1; /* Allows the details to fill the remaining space */
}

.profile-ladder-name {
    display: block;
    font-weight: bold;
}

.profile-ladder-rank {
    display: block;
    color: #555; /* Adjust color as needed */
}

/* Add this to your CSS file */

/* Style for WebKit browsers */
.messages-chat-content::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
}

.messages-chat-content::-webkit-scrollbar-track {
    background: #18191a; /* Background color of the scrollbar track */
}

.messages-chat-content::-webkit-scrollbar-thumb {
    background-color: #4a4a4a; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Round the corners of the scrollbar thumb */
    border: 3px solid #18191a; /* Adds padding around the thumb */
}

/* Style for Firefox */
.messages-chat-content {
    scrollbar-width: thin; /* Sets the width of the scrollbar */
    scrollbar-color: #4a4a4a #18191a; /* Sets the color of the thumb and track */
}

/* Style for WebKit browsers */
.messages-rooms-list::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
}

.messages-rooms-list::-webkit-scrollbar-track {
    background: #18191a; /* Background color of the scrollbar track */
}

.messages-rooms-list::-webkit-scrollbar-thumb {
    background-color: #4a4a4a; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Round the corners of the scrollbar thumb */
    border: 3px solid #18191a; /* Adds padding around the thumb */
}

/* Style for Firefox */
.messages-rooms-list {
    scrollbar-width: thin; /* Sets the width of the scrollbar */
    scrollbar-color: #4a4a4a #18191a; /* Sets the color of the thumb and track */
}

/* Add the rest of your CSS here */
.messages-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.messages-header-h3 {
    margin: 0;
    padding: 0;
}

.toggle-timestamp-button {
    border: none;
    background: none;
    color: #ccc;
    cursor: pointer;
    font-size: 1.5rem;
}

.toggle-timestamp-button:hover {
    color: #fff;
}

.chat-message:hover::after {
    content: attr(data-timestamp);
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    border-radius: 5px;
    top: -25px;
    right: 0;
    z-index: 10;
    white-space: nowrap;
}

.message-timestamp {
    display: block;
    font-size: 0.75rem;
    color: #aaa;
    margin-top: 5px;
}

.messages-container {
    display: flex;
    height: 85vh;
    color: #ccc; /* Light gray text color for readability */
}

.message-container {
    position: relative;
    background-color: #18191a;
}

.messages-sidebar {
    width: 240px;
    background-color: #2a2b2d; /* Dark gray for the sidebar */
    padding: 10px 0 10px 10px;
    border-right: 1px solid #444; /* Slightly lighter gray for separation */
}

.messages-sidebar.show {
  transform: translateX(0);
}

.messages-sidebar.hide {
  transform: translateX(-100%);
}

.messages-hamburger-button {
  background: none;
  border: none;
  color: #aaa;
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.messages-hamburger-button.open {
  transform: rotate(90deg); /* Rotate 90 degrees when sidebar is open */
}

.messages-tab {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #444;
}

.messages-tab:hover, .messages-tab.active {
    background-color: #333; /* Slightly lighter gray for hover and active states */
}

.messages-rooms-list {
    overflow-y: auto;
    max-height: 60vh;
}

.messages-room {
    padding: 10px;
    cursor: pointer;
}

.messages-room:hover, .messages-room.active {
    background-color: #333; /* Hover state for rooms */
}

.messages-chat-area {
    flex-grow: 1; /* Ensure it grows to fill the space */
    display: flex;
    flex-direction: column;
    background-color: #18191a;
    padding: 20px;
    max-width: 800px; /* Set the max width of the entire chat area, not just the content */
    margin-left: auto; /* Center the chat area horizontally */
    margin-right: auto; /* Center the chat area horizontally */
    height: 85vh; /* Optional: Adjust based on your design needs */
}

.messages-chat-content {
    background-color: #18191a; /* Very dark background to match the chat area */
    color: #ccc; /* Light gray text color for readability */
    width: 100%; /* Use full available width */
    flex-grow: 1; /* Allow it to expand and fill the space */
    padding: 10px; /* Padding inside the container */
    overflow-y: auto; /* Allow scrolling for overflow content */
    box-sizing: border-box; /* Include padding and border in the width and height */
    display: flex;
    flex-direction: column;
}

#chatLog {
    width: 100%; /* Full width to match the parent container */
    height: 100%; /* Expand to fill the available height */
    background-color: #18191a; /* Very dark background to match the chat area */
    color: #ccc; /* Light gray text color for readability */
    border: none; /* No border for a seamless look */
    resize: none; /* Prevent resizing the textarea */
    padding: 10px; /* Consistent padding with other elements */
    box-sizing: border-box; /* Include padding in the width and height */
    overflow-y: auto; /* Allow vertical scrolling */
}

.chat-message {
    display: block;
    padding: 5px 10px;
    margin: 5px 0;
    border-radius: 10px;
    max-width: 60%;
    word-wrap: break-word; /* Ensure long words break and do not overflow */
}

.my-message {
    align-self: flex-end;
    background-color: #dcf8c6; /* Light green background for user messages */
    color: #000;
}

.other-message {
    align-self: flex-start;
    background-color: lightgrey; /* Shade of gray background for other messages */
    color: #000;
}

.messages-input-area {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the message input */
    margin-top: auto; /* Keeps the input area at the bottom */
    padding: 10px;
    max-width: 800px; /* Set the max width of the input area */
    margin-left: auto; /* Center the input area horizontally */
    margin-right: auto; /* Center the input area horizontally */
    width: 100%; /* Ensure it expands to max width */
}

.message-send-button {
    height: 100px;
}

.form-control.messages-input {
    flex-grow: 1;
    background-color: transparent; /* Make input background transparent */
    color: #ddd; /* Lighter text color for visibility */
    border: 2px solid #ddd; /* Light gray border for visibility */
    height: 50px; /* Maintain input height */
    border-radius: 25px; /* Fully round the input */
    padding: 0 15px; /* Padding inside the input */
    margin-right: 10px;
}

.messages-send-button {
    width: 50px; /* Match height with the input for a circular shape */
    height: 50px; /* Make the button circular */
    background-color: #fff9; /* Dark blue background for the button */
    border: none;
    border-radius: 50%; /* Fully round the button */
    padding: 0; /* Remove padding */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.messages-send-button:hover {
    background-color: #627984; /* Lighter shade for hover */
}

/* Add this to your CSS file */
.chat-message:hover::after {
    content: attr(data-timestamp);
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    border-radius: 5px;
    top: -25px;
    right: 0;
    z-index: 10;
    white-space: nowrap;
}

.form-control, .btn-success {
    margin-bottom: 10px;
}

.filters-container {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    justify-content: center;
}

.filter-button {
    margin-top: 10px;
}

/* Update the CSS for the navigation bar */
.event-page-navigation {
    width: calc(100% - 40px);
    text-align: center;
    text-decoration: underline;
    margin-right: 20px;
}

.event-page-nav-link {
    margin-left: 25px;
    margin-right: 25px;
    color: #fff; /* Light color for the text */
    text-decoration: none;
    font-size: 16px; /* Larger font size for better readability */
    font-weight: 500; /* Medium weight for better visibility */
}

.add-court-button {
    width: 150px;
    margin: 0 0 20px;
    text-decoration: underline;
    color: white;
    background: none;
    border: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.add-court-button:hover {
    color: #0098b9;
}

.add-court-plus-button {
    background-color: green;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remove-court-plus-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-event-button-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.venmo-icon {
    margin-left: 20px;
    width: 35px;  /* Adjust the size to fit the Venmo icon */
    height: 35px; /* Adjust the size to fit the Venmo icon */
    transition: transform 0.6s linear;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    background-image: url('images/venmo.png');
}

.zelle-icon {
    margin-left: 20px;
    width: 35px;  /* Adjust the size to fit the Venmo icon */
    height: 35px; /* Adjust the size to fit the Venmo icon */
    transition: transform 0.6s linear;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    background-image: url('images/zelle.png');
}

.venmo-handle {
    text-align: center;
    width: 140px;
    margin-left: 5px;
    margin-right: 5px;
}

.venmo-qr-container {
    position: relative;
    width: 250px;
    height: 250px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}

.venmo-qr {
    width: 100%;
    height: 100%;
}

.close-qr {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
}

.unread-indicator {
    height: 6px;
    width: 6px;
    background-color: #ff7f50;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
}

.venmo-link-container {
    cursor: pointer;
}

.zelle-link-container {
    margin-top: 20px;
    cursor: pointer;
}

.tennis-scoreboard-no-matchup {
    margin-top: 20px;
}

.event-host-options-lock-button {
    margin-bottom: 30px;
}

.event-host-options-select-remove-user {
    width: 300px;
    max-width: 300px;
    min-width: 300px;
}

.event-host-options-change-rank {
    width: 120px;
    max-width: 120px;
    min-width: 120px;
    height: 40px;
    margin-top: 10px;
}

.event-host-options-userTable {
    max-width: 550px;
    color: white;
}

.table th, .table td {
    color: white; /* Ensures the text in the table headers and cells are also white */
}

.user-guide {
    margin-left: 20px;
    margin-right: 20px;
}
.user-guide-section-spacing {
    margin-bottom: 50px; /* Adjust the spacing as needed */
}

.faq-images {
    background-size: contain;  /* Ensure the image fits without stretching */
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    position: relative;
    width: 300px; /* Ensures the image container uses all available space */
}

.faq-navigate-to-ladder {
    background-image: url('images/faqs/navigate_to_ladder.png');
    height: 75px;
}

.faq-ladder-to-join {
    background-image: url('images/faqs/ladder_to_join.png');
    height: 364px;
}

.faq-join-ladder {
    background-image: url('images/faqs/join_ladder.png');
    height: 429px;
}

.faq-find-event-in-ladder {
    background-image: url('images/faqs/find_event_in_ladder.png');
    height: 206px;
}

.faq-find-event-in-events-tab {
    background-image: url('images/faqs/find_event_in_events_tab.png');
    height: 428px;
}

.faq-event-going {
    background-image: url('images/faqs/event_going.png');
    height: 261px;
}

.faq-event-matchups {
    background-image: url('images/faqs/event_matchups.png');
    height: 480px;
}

.faq-event-your-match {
    background-image: url('images/faqs/event_your_match.png');
    height: 234px;
}

.faq-score-submission1 {
    background-image: url('images/faqs/score_submission1.png');
    height: 623px;
}

.faq-score-submission2 {
    background-image: url('images/faqs/score_submission2.png');
    height: 437px;
}

.faq-medals {
    background-image: url('images/faqs/medals.png');
    height: 256px;
}

.faq-new-rank {
    background-image: url('images/faqs/new_ranks.png');
    height: 300px;
}

.email-subscription-container {
    margin-left: 20px;
    margin-top: 20px;
}

.email-subscription-buttons {
    width: 120px;
}

.email-subscription-message {
    margin-top: 20px;
    width: 350px;
}

.account-manage-email {
    margin-bottom: 50px;
    display: flex;
    justify-content: center; /* Centers children horizontally in the container */
    align-items: center; /* Centers children vertically in the container */
    height: 100%; /* Ensure the container has a height, might adjust as needed */
}

.self-rate-container {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    align-items: center; /* Aligns items vertically */
    justify-content: center; /* Aligns items horizontally */
    min-height: 600px; /* Ensures the container takes up the full height of the viewport */
}

.self-rate-button {
    margin-bottom: 20px;
}

.host-options-rankChangeContainer {
    display: flex;
    gap: 10px; /* Adjust space between elements */
}

.tennis-scoreboard-no-match {
    margin-top: 30px;
    font-style: italic;
}

/* Footer.css */
.footer-social-icon {
    font-size: 24px;
    /* Adjust this value as needed */
}

.footer-content a.footer-text-white {
    color: #fff;
    /* Ensures that the text color is white */
    transition: color 0.3s ease-in-out;
    /* Optional: adds a smooth transition effect */
    padding-left: 5px;
    padding-right: 5px;
}

.footer-content a.footer-text-white:hover {
    color: #ccc;
    /* Changes the color on hover, adjust as needed */
}

.footer-content {
    padding-top: 10px;
}

.footer-container {
    /*margin-top: 40px;*/
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

/* Additional styling for the victory theme */
.victory-theme {
    background-color: #ffdd57;
    /* Golden-yellow for victory */
    color: #333;
    /* Dark text for contrast */
    font-weight: bold;
}

@media (min-width: 550px) { /* Assuming 768px is your breakpoint for mobile */
    .event-page-navigation {
        display: none;
    }
}

@media (max-width: 550px) {
    .landing-page-left-container {
        padding: 0;
        margin: 0;
        display: none;
    }

    .landing-page-right-container {
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .landing-page-content {
        text-align: center;
        padding-top: 55px;
    }

    .landing-page-image-mobile {
        background-image: url('images/ball_transparent.png');
        background-size: contain;  /* Ensure the image fits without stretching */
        background-position: center;
        background-repeat: no-repeat;
        color: white;
        position: relative;
        width: 350px; /* Ensures the image container uses all available space */
        height: 350px;
    }

    .landing-page-description {
        text-align: center;
    }

    .nav-content {
        padding: 0;
        font-size: 0.9em;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav-items {
        margin-right: 0;
    }

    .ladder-page-container {
        flex-direction: column;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
    }

    .ladder-page-container2 {
        flex-direction: column;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
    }

    .ladder-page-image {
        width: calc(100% - 40px);
        height: auto;
        display: block;
        margin: 10px 0 0;
    }

    .ladder-page-description {
        width: calc(100% - 40px);
        height: auto;
        display: block;
        margin: 10px 0 0;
    }

    .ladder-page-description2 {
        width: calc(100% - 40px);
        height: auto;
        display: block;
        margin: 10px 0 0;
    }

    .account-info {
        margin-left: 0;
    }

    .form-signin-discover {
        flex-direction: column;
        /* Stack the children vertically */
    }

    .dropdown-discover,
    .form-floating,
    .btn-primary,
    .landing-page-search-button {
        flex: 0 0 100%;
        /* Make each child take the full width of the container */
        max-width: 100%;
        /* Override any max-width set on larger screens */
    }

    .discover-left-square {
        width: 100%;
        max-width: none;
        min-width: 0;
    }

    .discover-right-square {
        height: 400px;
        /* Adjust height as necessary */
        width: 95%;
        max-width: none;
        min-width: 0;
        margin-bottom: 20px;
        margin-left: 10px;
    }

    .discover-right-square-top {
        width: 95%;
        max-width: none;
        min-width: 0;
        margin-left: 10px;
        margin-bottom: 0;
    }

    .container-signin-discover {
        padding-bottom: 10px;
        margin: 10px;
        width: 100%;
        display: flex; /* Enable Flexbox */
        flex-direction: column; /* Align children vertically */
        align-items: center; /* Center-align items horizontally */
    }

    .discover-header-container {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Align items to the start (left) */
        width: 90%;
        margin-left: 5%;
    }

    .slider-container {
        margin-left: 0;
        margin-right: 0;
    }

    .profile-banner-container {
        grid-template-columns: 1fr;
        /* Stack all items in a single column */
    }

    .profile-match-history-container {
        width: 90%;
        left: 5%;
    }

    .profile-ladder-pill {
        width: 100%; /* Full width for smaller screens */
    }

    /* Slide */
    .slide {
        padding: 0;
    }

    /* Slider Container */
    .slider-container {
        width: 90%;
        margin-left: 18px;
        margin-top: 20px;
    }

    /* Hide the slick dots */
    .slick-slider .slick-dots {
        display: none !important;
    }

    .toggle-timestamp-button.open {
        display: none;
    }

    .messages-input-area.open {
        display: none;
    }

    .messages-header-h3.open {
        display: none;
    }

    .messages-chat-content.open {
        display: none;
    }

    .back-button-absolute {
        position: relative;
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .messages-chat-area {
        padding-top: 0;
    }

    .ladder-page-container2 {
        margin-bottom: 30px;
    }

    .host-ladder-button {
        margin-right: 40px;
    }

    .matchhistory-custom-table {
        min-width: 650px;
    }
}